/*.table-striped-rows tr:nth-child(2n) td {*/
/*    background-color: #eff7f6;*/
/*}*/
/*.table-striped-rows thead {*/
/*    background-color: #f1f1f1;*/
/*}*/

.table-row-light {
    background-color: #f1f1f1;
}
.table-row-dark {
    background-color: #eff7f6;
}
body {
    padding: .5em;
    font-family: "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: normal;
    color: #333333;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 14px;
}
.center-demo {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.p-col-d {
    display: table-cell;
}
.p-col-m {
    display: none;
}
@media screen and (max-width: 1024px) {
    .p-col-d {
        display: none;
    }
    .p-col-m {
        display: inline-block;
    }
}

.datatable-doc-demo .participant-badge {
    border-radius: 2px;
    padding: 0.25em 0.5em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
}

.datatable-doc-demo .mp1-skipped-completed {
    border-radius: 2px;
    padding: 0.25em 0.5em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;
}

.datatable-doc-demo .participant-badge.status-Yes {
    background-color: #C8E6C9;
    color: #256029;
}
.datatable-doc-demo .participant-badge.status-No {
    background-color: #FFCDD2;
    color: #C63737;
}
/*.datatable-doc-demo .participant-badge.status-NA {*/
/*    background-color: #FFD700;*/
/*    color: #8A5340;*/
/*}*/
.datatable-doc-demo .participant-badge.status--- {
    background-color: #B3E5FC;
    color: #23547B;
}
/*.datatable-doc-demo .customer-badge.status--- {*/
/*    background-color: #ECCFFF;*/
/*    color: #694382;*/
/*}*/
/*.datatable-doc-demo .customer-badge.status-proposal {*/
/*    background-color: #FFD8B2;*/
/*    color: #805B36;*/
/*}*/
.datatable-doc-demo .p-multiselect-representative-option {
    display: inline-block;
    vertical-align: middle;
}
.datatable-doc-demo .p-multiselect-representative-option img {
    vertical-align: middle;
    width: 24px;
}
.datatable-doc-demo .p-multiselect-representative-option span {
    margin-top: 0.125em;
}
.datatable-doc-demo .flag {
    width: 30px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
}
.datatable-doc-demo .p-paginator .p-dropdown {
    float: left;
}
.datatable-doc-demo .p-paginator .p-paginator-current {
    float: left;
}
.datatable-doc-demo .p-progressbar {
    height: 8px;
    background-color: #D8DADC;
}
.datatable-doc-demo .p-progressbar .p-progressbar-value {
    background-color: #00ACAD;
    transition: none;
}
.datatable-doc-demo .p-column-filter {
    display: block;
}

.datatable-doc-demo .p-datatable-scrollable-header {
    overflow: visible;
}

/*class="p-dropdown-items-wrapper"*/

.datatable-doc-demo .p-column-filter input {
    width: 100%;
}
.datatable-doc-demo .p-datatable-globalfilter-container {
    float: right;
}
.datatable-doc-demo .p-datatable-globalfilter-container input {
    width: 200px;
}
.datatable-doc-demo .p-datepicker {
    min-width: 25em;
}
.datatable-doc-demo .p-datepicker td {
    font-weight: 400;
}
.datatable-doc-demo .p-datatable.p-datatable-customers {
    /*box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);*/
}
.datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-header {
    border: 0 none;
    padding: 12px;
    text-align: left;
    font-size: 20px;
}
.datatable-doc-demo .p-datatable.p-datatable-customers .p-paginator {
    border: 0 none;
    padding: 1em;
    text-align: right !important;
}
.datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th {
    border: 0 none;
    text-align: left;
}
.datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-thead > tr > th.p-filter-column {
    border-top: 1px solid #c8c8c8;
}
.datatable-doc-demo .p-datatable.p-datatable-customers .p-datatable-tbody > tr > td {
    border: 0 none;
    cursor: auto;
}
.datatable-doc-demo .p-datatable.p-datatable-customers .p-dropdown-label:not(.p-placeholder) {
    text-transform: uppercase;
}

body .p-button {
    background-color: #008385;
    border: 1px solid #008385;
}

body .p-datatable .p-sortable-column.p-highlight {
    background-color: #008385;
}

/*span.p-button-icon-left.pi.pi-plus*/
/*{*/
    /*display: none;*/
/*}*/


.datatable-doc-demo .participant-badge.gender-Male {
    background-color: #FEEDAF;
    color: #8A5340;
}
.datatable-doc-demo .participant-badge.gender-Female {
    background-color: #B3E5FC;
    color: #23547B;
}


.datatable-doc-demo .participant-badge.recruitmentStatus-Active {
    background-color: #C8E6C9;
    color: #256029;
}
.datatable-doc-demo .participant-badge.recruitmentStatus-Inactive {
    background-color: #FFCDD2;
    color: #C63737;
}

.datatable-doc-demo .participant-badge.p-status-All {
    background-color: #C8E6C9;
    color: #256029;
}
.datatable-doc-demo .participant-badge.p-status-Due {
    background-color: #FFCDD2;
    color: #C63737;
}
.datatable-doc-demo .participant-badge.recruitmentStatus--- {
    background-color: #B3E5FC;
    color: #23547B;
}
/* FOR PARENT RECRUITMENT STATUSES*/
.datatable-doc-demo .participant-badge.ParentRecruitmentStatus-ELIGIBLE {
    background-color: #C8E6C9;
    color: #256029;
}

.datatable-doc-demo .participant-badge.ParentRecruitmentStatus-NOT_ELIGIBLE {
    background-color: #FEEDAF;
    color: #8A5340;
}
.datatable-doc-demo .participant-badge.ParentRecruitmentStatus-UNCONTACTABLE {
    background-color: #FFCDD2;
    color: #C63737;
}

.datatable-doc-demo .participant-badge.ParentRecruitmentStatus-APPROACHED {
    background-color: #B3E5FC;
    color: #23547B;
}

.datatable-doc-demo .participant-badge.ParentRecruitmentStatus-DID_NOT_RESPOND {
    background-color: #c7a5b1;
    color: #784040;
}
.datatable-doc-demo .participant-badge.ParentRecruitmentStatus-DECLINED {
    background-color: #f6b2b2;
    color: #a31919;
}
.datatable-doc-demo .participant-badge.ParentRecruitmentStatus-RECRUITED {
    background-color: #a8d5d0;
    color: #204440;
}
.datatable-doc-demo .participant-badge.ParentRecruitmentStatus-WITHDREW {
    background-color: #ffffb2;
    color: #3d3d3d;
}

.datatable-doc-demo .participant-badge.ParentRecruitmentStatus-RECRUITED_THEN_UNCONTACTABLE {
    background-color:  #ffd5a7;
    color: #295651;
}

/* MP1 Completed/Skipped Status */
.datatable-doc-demo .mp1-skipped-completed.status-True {
    background-color: #C8E6C9;
    color: #256029;
}

.datatable-doc-demo .mp1-skipped-completed.status-False {
    background-color: #ff99aa;
    color: #042c48;
}

/* FOR TEEN RECRUITMENT STATUSES*/
.datatable-doc-demo .participant-badge.ParticipantRecruitmentStatus-ELIGIBLE {
    background-color: #C8E6C9;
    color: #256029;
}

.datatable-doc-demo .participant-badge.ParticipantRecruitmentStatus-NOT_ELIGIBLE {
    background-color: #FEEDAF;
    color: #8A5340;
}
.datatable-doc-demo .participant-badge.ParticipantRecruitmentStatus-UNCONTACTABLE {
    background-color: #FFCDD2;
    color: #C63737;
}

.datatable-doc-demo .participant-badge.ParticipantRecruitmentStatus-APPROACHED {
    background-color: #B3E5FC;
    color: #23547B;
}

.datatable-doc-demo .participant-badge.ParticipantRecruitmentStatus-DID_NOT_RESPOND {
    background-color: #c7a5b1;
    color: #784040;
}

.datatable-doc-demo .participant-badge.ParticipantRecruitmentStatus-PARENT_CONSENT_UNKNOWN {
    background-color: #ff99aa;
    color: #042c48;
}
.datatable-doc-demo .participant-badge.ParticipantRecruitmentStatus-DECLINED_BY_PARENT {
    background-color: #f6b2b2;
    color: #05375b;
}
.datatable-doc-demo .participant-badge.ParticipantRecruitmentStatus-DECLINED_BY_TEEN {
    background-color: #fdd0cd;
    color: #063833;
}
.datatable-doc-demo .participant-badge.ParticipantRecruitmentStatus-RECRUITED {
    background-color: #a8d5d0;
    color: #204440;
}
.datatable-doc-demo .participant-badge.ParticipantRecruitmentStatus-WITHDREW_BY_PARENT {
    background-color: #ffff93;
    color: #3d3d3d;
}

.datatable-doc-demo .participant-badge.ParticipantRecruitmentStatus-WITHDREW_BY_TEEN {
    background-color: #ffffe0;
    color: #183330;
}

.datatable-doc-demo .participant-badge.ParticipantRecruitmentStatus-RECRUITED_THEN_UNCONTACTABLE {
    background-color:  #ffd5a7;
    color: #295651;
}


/* FOR SCHOOL STATUSES*/
.datatable-doc-demo .participant-badge.school-status-STILL_GOING_TO_SCHOOL{
    background-color: #C8E6C9;
    color: #256029;
}
.datatable-doc-demo .participant-badge.school-status-GRADUATED_FROM_YEAR_12{
    background-color: #a8d5d0;
    color: #204440;
}
.datatable-doc-demo .participant-badge.school-status-LEFT_SCHOOL_BUT_DIDNT_GRADUATE {
    background-color: #FFCDD2;
    color: #C63737;
}
.datatable-doc-demo .participant-badge.school-status--- {
    background-color: #B3E5FC;
    color: #23547B;
}
.datatable-doc-demo .participant-badge.school-status-EMPTY{
    background-color: #B3E5FC;
    color: #23547B;
}
/*Still going to school", "Graduated from Year 12" , "Left school but didn't graduate"*/

/* FOR SCHOOL Year STATUSES*/
.datatable-doc-demo .participant-badge.school-status-year-YEAR_9_OR_BELOW {
    background-color: #C8E6C9;
    color: #256029;
}
.datatable-doc-demo .participant-badge.school-status-year-YEAR_10 {
    background-color: #FEEDAF;
    color: #8A5340;
}
.datatable-doc-demo .participant-badge.school-status-year-YEAR_11 {
    background-color: #ff99aa;
    color: #042c48;
}

.datatable-doc-demo .participant-badge.school-status-year-YEAR_12 {
    background-color: #ffffb2;
    color: #042c48;
}

.datatable-doc-demo .participant-badge.school-status-year-IB {
    background-color: #f6b2b2;
    color: #05375b;
}
.datatable-doc-demo .participant-badge.school-status-year--- {
    background-color: #B3E5FC;
    color: #23547B;
}

.datatable-doc-demo .participant-badge.school-status-year-EMPTY {
    background-color: #B3E5FC;
    color: #23547B;
}
/*"Year 9 or below" ,"Year 10", "Year 11" ,"IB"*/
